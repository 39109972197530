<template>
  <header
    class="pointer-events-none sticky top-0 z-header"
    :class="props.headerClass"
  >
    <div class="pointer-events-none flex items-start">
      <div
        class="w-full"
        :style="{
          // Fixes bouncing navigation on certain point on scroll
          height: INITIAL_HEADER_HEIGHT
            ? `${INITIAL_HEADER_HEIGHT}px`
            : undefined,
        }"
      >
        <div
          ref="headerEl"
          class="pointer-events-auto w-full"
        >
          <slot name="notifications" />
          <slot name="headerTopLinks" />
          <div
            class="pointer-events-auto bg-primary transition-all ease-linear will-change-transform"
            :class="{ 'py-2': isSticky, 'py-[1.031rem]': !isSticky }"
          >
            <div class="container flex flex-row items-center">
              <div
                v-if="$slots.region"
                class="mr-auto flex items-center lg:hidden"
              >
                <slot name="region" />
              </div>
              <button
                class="mr-16 hidden h-5 flex-col justify-between lg:flex"
                title="Menu"
                @click.prevent="emit('hamburger-click')"
              >
                <span class="h-1 w-11 bg-white" />
                <span class="h-1 w-11 bg-white" />
                <span class="h-1 w-11 bg-white" />
              </button>
              <NuxtLink :to="props.baseUrl">
                <UiLogo
                  class="lg:mr-7"
                  variant="white"
                  title="Rawlplug"
                />
              </NuxtLink>
              <div
                v-if="$slots.search"
                class="hidden w-full max-w-[760px] pr-4 lg:block"
              >
                <slot name="search" />
              </div>
              <div
                v-if="$slots.icons"
                class="ml-auto flex gap-5 text-white"
              >
                <slot name="icons" />
              </div>
            </div>
          </div>
          <div class="pointer-events-auto shadow-lg">
            <slot name="headerBottomLinks" />
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from 'vue'
import UiLogo from '../UiLogo/UiLogo.vue'
import { usePageHeaderUi } from '../../composables/usePageHeaderUi'

interface UiHeaderPage {
  baseUrl: string
  headerClass: string
}

defineOptions({
  inheritAttrs: false,
})

const props = defineProps<UiHeaderPage>()

const emit = defineEmits<{
  (e: 'change-height', id: number): void
  (e: 'change-sticky', value: boolean): void
  (e: 'hamburger-click'): void
}>()

const { init, isSticky, isHeaderSticky, INITIAL_HEADER_HEIGHT } = usePageHeaderUi(emit)

const headerEl = ref<HTMLElement | null>(null)

onMounted(() => {
  if (headerEl.value) {
    init(headerEl.value)
  }
})
onUnmounted(() => document.removeEventListener('scroll', isHeaderSticky))
</script>
